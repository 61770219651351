html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #1b1b3a;
}
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #1b1b3a;
}
main {
  height: 80%;
}
